module.exports = [{
  plugin: require('/Users/maca/Projects/blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
  options: {
    "plugins": [],
    "maxWidth": 590
  }
}, {
  plugin: require('/Users/maca/Projects/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-130279868-1"
  }
}, {
  plugin: require('/Users/maca/Projects/blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/Users/maca/Projects/blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
  options: {
    "plugins": [],
    "pathToConfigModule": "src/utils/typography"
  }
}, {
  plugin: require('/Users/maca/Projects/blog/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];